export class EventMessageModel {
    type?: string
    key?: string
    value?: any
    timestamp?: string

    constructor ({
        type,
        key,
        value,
        timestamp
    }:
        {
            type?: string,
            key?: string,
            value?: any,
            timestamp?: string
        } = {}) {
        this.type = type
        this.key = key
        this.value = value
        this.timestamp = timestamp
    }
}