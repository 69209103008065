import { Carrier } from '@/model/carrierListModel'
import { EventMessageModel } from '@/model/components/eventMessageModel'
import { Device } from '@/model/deviceListModel'
import { LoginModel } from '@/model/loginModel'
import { SProduct } from '@/model/sProductListModel'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { PlatformInfoModel } from '@/model/platformInfoModel'

const localStore = createStore({
    state: {
        eventMessage: new EventMessageModel(),
        token: '',
        loginModel: new LoginModel(),
        addCarrier: new Carrier(),
        addDevice: new Device(),
        addsProduct: new SProduct(),
        menuListAllRoutes: [],
        platformInfo: new PlatformInfoModel(),
        themeIndex: 1,
        fontIndex: 1,
        isPlayer: false
    },
    mutations: {
        sendEventMessage (state, eventMessage) {
            eventMessage.timestamp = Date.now()
            state.eventMessage = eventMessage
        },
        setToken (state, token) {
            state.token = token
        },
        setLoginModel (state, loginModel) {
            state.loginModel = JSON.parse(JSON.stringify(loginModel))
            state.token = loginModel?.token ?? ''
        },
        setAddCarrier (state, carrier) {
            state.addCarrier = carrier
        },
        setAddDevice (state, device) {
            state.addDevice = device
        },
        setAddsProduct (state, sProduct) {
            state.addsProduct = sProduct
        },
        setMenuListAllRoutes (state, menuListAllRoutes) {
            state.menuListAllRoutes = menuListAllRoutes
        },
        setPlatformInfo (state, platformInfo) {
            state.platformInfo = platformInfo
        },
        setThemeIndex (state, themeIndex) {
            state.themeIndex = themeIndex
        },
        setFontIndex (state, fontIndex) {
            state.fontIndex = fontIndex
        },
        setIsPlayer (state, isPlayer) {
            state.isPlayer = isPlayer
        }
    },
    actions: {},
    plugins: [createPersistedState({
        storage: window.localStorage
    })]
})

export default localStore