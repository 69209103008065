export class LoginModel {
  id!: number;
  platform_id!: number;
  parent_id!: number;
  name!: string;
  phone!: string;
  logo!: string;
  address!: string;
  description!: string;
  info_share!: boolean;
  status!: number;
  product_ids!: number[];
  token!: string;
  token_time!: number;
  created_at!: string;

  static toObject (data:any) :LoginModel {
      return Object.assign(new LoginModel(), data)
  }
}