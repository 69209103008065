export class Menu {
    id!: number;
    pid!: number;
    name!: string;
    route!: string;
    icon!: string;
    click?: any;
    all_children!: Menu[];
}

export class MenuListModel {
    code!: number;
    message!: string;
    data!: Menu[];
    static toObject (data: any): MenuListModel {
        return Object.assign(new MenuListModel(), data)
    }
}