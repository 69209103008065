export class PlatformInfoModel {
    id!: number;
    platform_id!: number;
    service_status!: boolean;
    service_notice!: string;
    name!: string;
    image!: string;
    user_default_avatar!: string[];
    containers_default_avatar!: string[];
    products_default_avatar!: string[];
    foot_info!: string;
    created_at!: string;
    updated_at!: string;

    static toObject (data: any): PlatformInfoModel {
        return Object.assign(new PlatformInfoModel(), data)
    }
}