import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const sessionStore = createStore({
    state: {
        token: '',
        screenDrawer: false
    },
    mutations: {
        setToken (state, token) {
            state.token = token
        },
        setScreenDrawer (state, screenDrawer) {
            state.screenDrawer = screenDrawer
        }
    },
    actions: {},
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})

export default sessionStore