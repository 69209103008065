export class Productsparam {
    id!: number;
    name!: string;
    custom_name!: string;
    unit!: string;
}

export class SProduct {
    id!: number;
    platform_id!: number;
    name!: string;
    product_type!: number;
    key!: string;
    description!: string;
    image!: string;
    frequency!: number;
    frequency_min!: number;
    frequency_max!: number;
    config!: any;
    created_at!: string;
    products_params!: Productsparam[];
}

export class SProductListModel {
    current_page!: number;
    data!: SProduct[];
    total!: number;
    static toObject (data: any): SProductListModel {
        return Object.assign(new SProductListModel(), data)
    }
}
