export class Carrier {
    id!: number;
    platform_id!: number;
    user_id!: number;
    container_id!: number;
    name!: string;
    image!: string;
    address!: string;
    address_map!: string;
    status!: number;
    created_at!: string;
    deleted_at!: null;
    counts!: number;
    container_counts!: number;
    device_counts!: number;
    belong_user_name!: string;
    belong_user_phone!: string;
    status_color!: string;
    status_text!: string;
    containers_name!: string;
}

export class CarrierListModel {
    current_page!: number;
    data!: Carrier[];
    total!: number;
    static toObject (data: any): CarrierListModel {
        return Object.assign(new CarrierListModel(), data)
    }
}