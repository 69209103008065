import { createStore } from 'vuex'

interface stateInt {
    data: any
    webSocket?: WebSocket
}

const state: stateInt = {
    // 推送消息
    data: {},
    webSocket: undefined
}

const webSocketStore = createStore({
    state,
    mutations: {
        // websocket初始化
        initWebsocket (state) {
            state.webSocket = new WebSocket(
                // 此处填写你要连接的ws地址
                'wss://equipment.ningjitime.com/wss/' + Math.random()
            )
            // 建立连接
            state.webSocket.onopen = function () {
                console.log('通讯开始')
                console.log(state.webSocket)
                // 发送心跳防止ws协议自动断联
                const interval = setInterval(() => {
                    // console.log(state.webSocket)
                    if (state.webSocket?.readyState === 1) {
                        state.webSocket?.send('1')
                        console.log('heart')
                    } else {
                        state.webSocket?.close()
                        clearInterval(interval)
                        console.log('断开')
                        webSocketStore.commit('initWebsocket')
                    }
                }, 1000 * 10)
            }
            // 接收服务端消息
            state.webSocket.onmessage = function (e) {
                // console.log('收到的数据：', e.data)
                // 如果数据对象为字符串，可转换为对象格式
                const data = JSON.parse(e.data)
                state.data = data
                console.log('收到的数据：', data)
            }
            state.webSocket.onerror = function () {
                console.log('通讯异常')
                // webSocketStore.commit('initWebsocket')
            }
            state.webSocket.close = function () {
                console.log('连接已断开')
                // webSocketStore.commit('initWebsocket')
            }
        }
    },
    actions: {
    },
    modules: {}
})

export default webSocketStore