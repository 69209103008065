export class Diagno {
    id!: number;
    product_id!: number;
    group_id!: any;
    title!: string;
    introduction!: string;
    content!: string;
    logic_output!: any;
    product_name!: string;
    params_group_name!: string;

    static toObject (data: any): Diagno {
        return Object.assign(new Diagno(), data)
    }
}

export class DiagnoListModel {
    current_page!: number;
    data!: Diagno[];
    total!: number;

    static toObject (data: any): DiagnoListModel {
        return Object.assign(new DiagnoListModel(), data)
    }
}