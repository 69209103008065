import { DeviceParamModel } from './deviceParamModel'

export class Device {
    id!: number;
    platform_id!: number;
    carriers_id!: number;
    product_id!: number;
    user_id!: number;
    params_group!: number[];
    frequency!: number;
    device_name!: string;
    device_key!: string;
    position!: string;
    phase!: string;
    image!: string;
    remark!: string;
    product_warnings!: number[];
    device_warnings!: number[];
    status!: number;
    created_at!: string;
    user_name!: string;
    user_logo!: string;
    user_phone!: string;
    carriers_name!: string;
    product_name!: string;
    status_color!: string;
    status_text!: string;
    statistics?: DeviceParamModel;
    device_id?: number;
}

export class DeviceListModel {
    current_page!: number;
    data!: Device[];
    total!: number;

    static toObject (data: any): DeviceListModel {
        return Object.assign(new DeviceListModel(), data)
    }
}