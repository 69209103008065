export class SessionMutations {
    static setToken = 'setToken'
    static setScreenDrawer = 'setScreenDrawer'
}

export class SessionActions {

}

// export default interface SessionModuleTypes {
//     token: string,
//     screenDrawer: boolean,
// }