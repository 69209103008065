<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script lang="ts" setup>
import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/jquery-ui/jquery-ui.min.js'
import 'admin-lte/plugins/jquery-validation/jquery.validate.min.js'
import 'admin-lte/plugins/jquery-validation/additional-methods.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import 'admin-lte/plugins/chart.js/Chart.min.js'
import 'admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js'
import 'admin-lte/plugins/summernote/summernote-bs4.min.css'
import 'admin-lte/plugins/summernote/summernote-bs4.min.js'
import 'admin-lte/plugins/summernote/lang/summernote-zh-CN.js'
import 'admin-lte/plugins/flot/jquery.flot.js'
import 'admin-lte/plugins/flot/plugins/jquery.flot.resize.js'
import 'admin-lte/plugins/flot/plugins/jquery.flot.pie.js'
import 'admin-lte/dist/js/adminlte.min.js'
import 'admin-lte/dist/css/adminlte.min.css'
import { onMounted, onUnmounted } from 'vue'
import { SessionMutations } from '@/store/modules/session/interface'
import sessionStore from '@/store/modules/session'
import localStore from '@/store/modules/local'
import router, { routesName } from '@/router'
import { setFont } from '@/service/tool/globalFunc'

const debounce = (fn: any, delay: any) => {
    let timer: any
    return (...args: any) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor (callback: any) {
        callback = debounce(callback, 200)
        super(callback)
    }
}

onMounted(() => {
    setFont(localStore.state.fontIndex)
    window.addEventListener('storage', syncDataWithStorage)
})

onUnmounted(() => {
    window.removeEventListener('storage', syncDataWithStorage)
})

function syncDataWithStorage (event: any) {
    if (event.key === 'vuex') {
        const newValue = JSON.parse(event.newValue)
        const loginModel = newValue.loginModel
        if (loginModel && loginModel.token !== sessionStore.state.token) {
            sessionStore.commit(SessionMutations.setToken, loginModel.token)
            if (localStore.state?.token || sessionStore.state?.token) {
                go()
            }
        }
        const message = newValue.eventMessage
        if (message && message.type === 'setFontIndex') {
            setFont(message.value)
        }
    }
}

function go () {
    const route = routesName.home
    router.push(route).then(() => {
        if (route === routesName.home) location.reload()
    })
}
</script>
<style lang="less">
@import '@/style/theme.less';
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
@font-face {
  font-family: 'HYYouKai-75W';
  src: url('@/assets/HYYouKai-75W.ttf');
}

// @font-face {
//   font-family: 'Alimama_DongFangDaKai_Regular';
//   src: url('@/assets/Alimama_DongFangDaKai_Regular.ttf');
// }
// @font-face {
//   font-family: 'HarmonyOS_Sans_Regular';
//   src: url('@/assets/HarmonyOS_Sans_Regular.ttf');
// }
// @font-face {
//   font-family: '抖音美好体';
//   src: url('@/assets/抖音美好体.otf');
// }
// @font-face {
//   font-family: '斗鱼追光体';
//   src: url('@/assets/斗鱼追光体.otf');
// }
// @font-face {
//   font-family: '三极行楷简体粗';
//   src: url('@/assets/三极行楷简体-粗.ttf');
// }
html,
body,
#app {
  height: 100%;
  background-color: transparent;
  font-family: 'Source Han Sans CN'; //Source Han Sans CN;
  //'HYYouKai-75W';
  word-break: break-word;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background: #cfcbcb;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 6px;
  background: #ededed;
}

label {
  margin-bottom: 0;
}

.btn-block {
  width: auto;
}

.btn-block+.btn-block {
  margin-top: 0;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 4px 16px;
  font-size: 14px;
}
</style>
