import { createStore } from 'vuex'
// import { menuList } from '@/router/menuList'
import { ThemeModel } from '@/model/components/themeModel'
import { MenuListModel } from '@/model/menuListModel'
import { Diagno } from '@/model/diagnoListModel'

const globalStore = createStore({
    state: {
        loginCode: 60,
        loginCodeMax: 60,
        loginCodeText: '发送验证码',
        registerCode: 60,
        registerCodeText: '发送验证码',
        // menuList: menuList,
        menuList: new MenuListModel(),
        selectAddress: '',
        themeModel: [
            new ThemeModel({
                bgColor: '',
                sidebarColor: '',
                footColor: '',
                fontClass: '',
                toolColor: '',
                themeColor: ['#F4F6F9', '#fff', '#212529', '#17A2B8', '#007BFF', '#fff', '#212529', '#212529', '#FFC107', '#fff', '#000'],
                themeClass: ['user-row-div-white', 'small-box-white', 'button-row-div-white',
                    'table-div-white', ''
                ],
                themeLinear: ['', '', '']
            }),
            new ThemeModel({
                bgColor: '#011736',
                sidebarColor: 'linear-gradient(to bottom, #011736, #136480)',
                footColor: '#07395D',
                fontClass: 'theme-font-blue',
                toolColor: '#093572',
                themeColor: ['#011736', '#042B55', '#fff', '', '', '#fff', '#F5E74F', '#1FC6FF', '', '#F5E74F', '#F5E74F'],
                themeClass: ['user-row-div-blue', 'small-box-blue', 'button-row-div-blue',
                    'table-div-blue', 'el-collapse-blue'
                ],
                themeLinear: ['linear-gradient(90deg, #084171 10%, #03234A 100%)', 'linear-gradient(90deg, #0A4E81 10%, #03234A 100%)',
                    'linear-gradient(180deg, #011736 10%, #136480 100%)'
                ]
            }),
            new ThemeModel({
                bgColor: '#1B2535',
                sidebarColor: 'linear-gradient(to bottom, #053F4D, #04323C)',
                footColor: '#1E313F',
                fontClass: 'theme-font-green',
                toolColor: '#2D3645',
                themeColor: ['#1B2535', '#1D2C3A', '#fff', '', '', '#fff', '#F5E74F', '#1FC6FF', '', '#F5E74F', '#F5E74F'],
                themeClass: ['user-row-div-green', 'small-box-green', 'button-row-div-green',
                    'table-div-green', 'el-collapse-green'
                ],
                themeLinear: ['linear-gradient(90deg, #214250 10%, #1D2C3A 100%)', 'linear-gradient(90deg, #1E313F 10%, #1E313F 100%)',
                    'linear-gradient(180deg, #1D2C3A 10%, #136480 100%)'
                ]
            })
        ],
        logicOut: false,
        logicIndex: 0,
        diagno: new Diagno(),
        nodeBtnId: 0,
        nodeBtnName: ''
    },
    mutations: {
        _loginCodeChange (state) {
            state.loginCode--
            state.loginCodeText = `重新发送(${state.loginCode.toString()})`
            if (state.loginCode < 0) {
                state.loginCode = state.loginCodeMax
                state.loginCodeText = '发送验证码'
            }
        },
        _registerCodeChange (state) {
            state.registerCode--
            state.registerCodeText = `重新发送(${state.registerCode.toString()})`
            if (state.registerCode < 0) {
                state.registerCode = state.loginCodeMax
                state.registerCodeText = '发送验证码'
            }
        },
        setMenuListModel (state, menuListModel) {
            state.menuList = menuListModel
        },
        setSelectAddress (state, address) {
            state.selectAddress = address
        },
        setThemeModel (state, themeModel) {
            state.themeModel = themeModel
        },
        setLogicOut (state, logicOut) {
            state.logicOut = logicOut
        },
        setLogicIndex (state, logicIndex) {
            state.logicIndex = logicIndex
        },
        setDiagno (state, diagno) {
            state.diagno = diagno
        },
        setNodeBtnId (state, nodeBtnId) {
            state.nodeBtnId = nodeBtnId
        },
        setNodeBtnName (state, nodeBtnName) {
            state.nodeBtnName = nodeBtnName
        }
    },
    actions: {
        setLoginCode ({ commit, state }) {
            if (state.loginCode === state.loginCodeMax) {
                const interval = setInterval(() => {
                    commit('_loginCodeChange')
                    if (state.loginCode === state.loginCodeMax) {
                        clearInterval(interval)
                    }
                }, 1000)
            }
        },
        setRegisterCode ({ commit, state }) {
            if (state.registerCode === state.loginCodeMax) {
                const interval = setInterval(() => {
                    commit('_registerCodeChange')
                    if (state.registerCode === state.loginCodeMax) {
                        clearInterval(interval)
                    }
                }, 1000)
            }
        }
    }
})

export default globalStore