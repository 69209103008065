export class ThemeModel {
    bgColor?: string
    sidebarColor?: string
    footColor?: string
    fontClass?: string
    toolColor?: string
    themeColor?: string[]
    themeClass?: string[]
    themeLinear?: string[]

    constructor ({
        bgColor,
        sidebarColor,
        footColor,
        fontClass,
        toolColor,
        themeColor,
        themeClass,
        themeLinear
    }:
        {
            bgColor?: string,
            sidebarColor?: string,
            footColor?: string,
            fontClass?: string,
            toolColor?: string,
            themeColor?: string[],
            themeClass?: string[],
            themeLinear?: string[],
        } = {}) {
        this.bgColor = bgColor
        this.sidebarColor = sidebarColor
        this.footColor = footColor
        this.fontClass = fontClass
        this.toolColor = toolColor
        this.themeColor = themeColor
        this.themeClass = themeClass
        this.themeLinear = themeLinear
    }
}