import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import globalStore from './store/modules/global'
import sessionStore from './store/modules/session'
import localStore from './store/modules/local'
import websocketStore from './store/modules/websocket'

const app = createApp(App)
app.use(globalStore).use(sessionStore).use(localStore).use(websocketStore).use(router).use(ElementPlus)
app.mount('#app')
