import { routesName } from '@/router'

const localExceptKey = ['themeIndex', 'fontIndex', 'isPlayer']

function localStorageClear () {
    const vuex: any = localStorage.getItem('vuex')
    if (vuex) {
        const vuexObject = JSON.parse(vuex)
        const keys = Object.keys(vuexObject)
        keys.forEach(key => {
            const index = localExceptKey.findIndex((item) => item === key)
            if (index === -1) {
                delete vuexObject[key]
            }
        })
        localStorage.setItem('vuex', JSON.stringify(vuexObject))
    }
}

export function loginOut () {
    localStorageClear()
    sessionStorage.clear()
    top!.location.href = '#' + routesName.login
}

export function bytesToMB (bytes?: number) {
    if (bytes) { return (bytes / 1024 / 1024).toFixed(2) } else return 0
}

export function getUploadsUrl (url: string) {
    const arr = url?.split('/uploads')
    if (arr?.length > 1) { url = '/uploads' + arr[1] }
    return url
}

export function setFont (index: number) {
    const element = document.getElementById('app')
    if (element) {
        switch (index) {
        case 0:
                element!.style.fontFamily = 'Source Han Sans CN'
            console.log(element!.style.fontFamily)
            break
        case 1:
                element!.style.fontFamily = 'HYYouKai-75W'
            console.log(element!.style.fontFamily)
            break
        default:
            break
        }
    }
}

export const signList1 = [
    {
        name: '>',
        value: '>'
    },
    {
        name: '<',
        value: '<'
    },
    {
        name: '=',
        value: '='
    },
    {
        name: '>=',
        value: '>='
    },
    {
        name: '<=',
        value: '<='
    }
]

export const signList2 = [
    {
        type: 2,
        name: '大于',
        value: '>'
    },
    {
        type: 4,
        name: '且',
        value: '&&'
    },
    {
        type: 2,
        name: '小于',
        value: '<'
    },
    {
        type: 4,
        name: '或',
        value: '||'
    },
    {
        type: 0,
        name: '左括号',
        value: '('
    },
    {
        type: 5,
        name: '右括号',
        value: ')'
    }
]
